import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'gatsby';
import { css } from '@emotion/core';
import groupBy from 'lodash/fp/groupBy';
import Layout from '../../organisms/Layout/Layout';
import blockContentSection from '../../molecules/BlockContentSection/BlockContentSection';
import { renderPageSections } from '../renderPageSections';
import PageWrapper from '../../organisms/PageWrapper/PageWrapper';
import {
  breakpoints,
  colors,
  fontSizes,
  fontWeights,
  lineHeights,
  unitConverter as uc,
} from '../../../styles/base';
import Section from '../../molecules/Section/Section';
import Link from '../../atoms/Link/Link';
import Text from '../../atoms/Text/Text';
import { Heading4 } from '../../atoms/Headings/Headings';
import useLocalize from '../../../hooks/useLocalize';
import Heading1 from '../../atoms/Headings/Heading1/Heading1';

const sectionCss = css`
  align-items: center;
  padding: ${uc('0px 20px 40px 20px')};
  background-color: ${colors.transparent};

  @media (${breakpoints.largeTablet}) {
    padding: ${uc('0px 40px 40px 40px')};
  }
`;

const counterSectionCss = css`
  ${sectionCss}
  display: flex;

  @media (${breakpoints.tablet}) {
    flex-wrap: wrap;
  }
`;

const termCss = css`
  display: contents;
`;

const textCss = css`
  margin: ${uc('0px 5px 0px 0px')};
  font-size: ${fontSizes.fourteen};
`;

const keyCss = css`
  ${textCss}
  color: ${colors.primary};
`;

const valueCss = css`
  ${textCss}
  display: inline;
`;

const headingCss = css`
  color: ${colors.lightGray.three};
`;

const letterSectionCss = css`
  margin-bottom: ${uc('30px')};
`;

const listCss = css`
  list-style-type: none;
`;

const listItemCss = css`
  margin-bottom: ${uc('20px')};
  line-height: ${uc('24px')};
`;

const blockContentWrapperCss = css`
  @media (${breakpoints.largeTablet}) {
    margin: ${uc('-40px 0px')};
  }

  @media (${breakpoints.smallDesktopMin}) {
    width: ${uc('850px')};
  }
`;

const headingTextCss = css`
  margin-left: ${uc('20px')};
  color: ${colors.darkGray.three};
  font-weight: ${fontWeights.bold};
  font-size: ${fontSizes.thirty};
  line-height: ${lineHeights.title};

  @media (${breakpoints.tablet}) {
    font-size: ${fontSizes.twentyEight};
  }

  @media (${breakpoints.mobile}) {
    font-size: ${fontSizes.twentyTwo};
  }
`;

const headingSectionCss = css`
  display: block;
  max-width: ${uc('900px')};
  margin: 0 auto ${uc('20px')};
  text-align: left;

  @media (${breakpoints.tablet}) {
    margin: ${uc('0 20px 20px')};
  }
`;

const containerCss = css`
  margin-top: ${uc('150px')};
  margin-bottom: ${uc('50px')};

  @media (${breakpoints.tablet}) {
    margin-top: ${uc('180px')};
    margin-bottom: ${uc('60px')};
  }
`;

const GlossaryPage = ({
  data: rawData,
  location: { href },
  pageContext: { locale },
}) => {
  if (!rawData) {
    return null;
  }

  const data = useLocalize(rawData, locale);
  const pageTerms = data.allSanityGlossaryTermPage.edges;
  const alphabeticallyGroupedTerms = groupBy(page => {
    const firstLetter = page.node.name.split('')[0];
    if (/[0-9]/.test(firstLetter)) {
      return '0-9';
    }
    return firstLetter;
  }, pageTerms);
  const termsKeys = Object.keys(alphabeticallyGroupedTerms);

  const sectionSchemaName = {
    blockContentSection,
  };

  const glossaryPageProps = {
    id: data.sanityGlossaryPage._id,
    name: data.sanityGlossaryPage.name,
    metadata: { ...data.sanityGlossaryPage._rawRoute, href },
    sections: data.sanityGlossaryPage._rawSections
      ? data.sanityGlossaryPage._rawSections.filter(section =>
          Object.keys(sectionSchemaName).some(v => v === section._type)
        )
      : [],
  };

  return (
    <Layout
      locale={locale}
      menuColor="primary"
      sanityId={glossaryPageProps.id}
      {...glossaryPageProps.metadata}
    >
      <PageWrapper>
        <div css={containerCss}>
          <Section css={blockContentWrapperCss}>
            <div css={headingSectionCss}>
              <Heading1 css={headingTextCss}>{glossaryPageProps.name}</Heading1>
            </div>
          </Section>

          <div css={blockContentWrapperCss}>
            {renderPageSections(glossaryPageProps.sections)}
          </div>

          <Section css={counterSectionCss}>
            {termsKeys.map(termKey => (
              <div key={termKey} css={termCss}>
                <Link to={`#${termKey}`} css={keyCss} isLocalized={false}>
                  {termKey}
                </Link>
                <Text
                  css={valueCss}
                >{`(${alphabeticallyGroupedTerms[termKey].length})`}</Text>
              </div>
            ))}
          </Section>

          <Section css={sectionCss}>
            {termsKeys.map(termKey => (
              <div id={`${termKey}/`} css={letterSectionCss} key={termKey}>
                <Heading4 css={headingCss}>{termKey}</Heading4>
                <ul css={listCss}>
                  {alphabeticallyGroupedTerms[termKey].map(
                    ({
                      node: {
                        _id,
                        name,
                        route: { slug },
                      },
                    }) => (
                      <li css={listItemCss}>
                        <Link key={_id} to={slug.current} isLocalized>
                          {name}
                        </Link>
                      </li>
                    )
                  )}
                </ul>
              </div>
            ))}
          </Section>
        </div>
      </PageWrapper>
    </Layout>
  );
};

GlossaryPage.propTypes = {
  data: PropTypes.shape({
    allSanityGlossaryTermPage: {},
    sanityGlossaryPage: {},
  }),
  location: PropTypes.shape({ href: PropTypes.string }),
  pageContext: PropTypes.shape({ locale: PropTypes.string }),
};

GlossaryPage.defaultProps = {
  data: {},
  location: { href: '' },
  pageContext: { locale: '' },
};

export default GlossaryPage;

export const query = graphql`
  query($slug: String, $sortField: [SanityGlossaryTermPageFieldsEnum]) {
    sanityGlossaryPage(route: { slug: { current: { eq: $slug } } }) {
      _id
      _rawRoute(resolveReferences: { maxDepth: 100 })
      _rawSections(resolveReferences: { maxDepth: 100 })
      name: _rawName(resolveReferences: { maxDepth: 100 })
    }
    allSanityGlossaryTermPage(sort: { order: ASC, fields: $sortField }) {
      edges {
        node {
          _id
          _type
          route {
            slug {
              current
            }
          }
          name: _rawName(resolveReferences: { maxDepth: 100 })
        }
      }
    }
  }
`;
